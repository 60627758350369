export enum SortingOrder {
  DESC = 'desc',
  ASC = 'asc',
}
export enum UserStatus {
  INACTIVE = 'inactive',
  NORMAL = 'normal',
  LOCKED = 'locked',
  TRASHED = 'trashed',
}

export enum EditorTypeEnum {
  TINY_MCE = 'tiny-mce',
  MARKDOWN = 'markdown',
  QUILL = 'quill',
}

export enum SOCIAL_MEDIA {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
  DOUYIN = 'douyin',
  XIAOHONGSHU = 'xiaohongshu',
}

export enum COMPONENTS_SIGN {
  HOT_TOPIC = 'HOT_TOPIC',
  RECOMMENDATION = 'RECOMMENDATION',
  CONTENT_RANKING = 'CONTENT_RANKING',
  FOURTH_CARD_IN_ROW = 'FOURTH_CARD_IN_ROW',
  TWO_CARD_IN_ROW = 'TWO_CARD_IN_ROW',
  LECTURER_ROW = 'LECTURER_ROW',
  REVIEW_ROW = 'REVIEW_ROW',
}

export enum COURSE_TYPE {
  COURSE = 'course',
  TOPIC = 'topic',
}

export enum COMPONENTS_CARD {
  HOT_TOPIC = 'hot',
  RECOMMENDATION = 'recommendation',
  CONTENT_RANKING = 'content',
  FOURTH_CARD_IN_ROW = 'forth',
  TWO_CARD_IN_ROW = 'two',
  LECTURER_ROW = 'lecturer',
  REVIEW_ROW = 'review',
}

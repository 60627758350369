export const getDefaultLanguageBasedOnApi = () => {
  if (
    String(process.env.NEXT_PUBLIC_APP_URL).includes('-global') &&
    !String(process.env.NEXT_PUBLIC_APP_URL).includes('-cn')
  ) {
    return 'en';
  } else {
    return 'zh';
  }
};

export const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE || getDefaultLanguageBasedOnApi();
export const localesSupport = ['en', 'zh'];
// MTS URL
export const enJsonUrl =
  'https://multilingual-gateway-uat.finpoints.tech/sandbox/multilingual-translation-system/DooAcademyTutor/v1.0.0/en_us.json';
export const scJsonUrl =
  'https://multilingual-gateway-uat.finpoints.tech/sandbox/multilingual-translation-system/DooAcademyTutor/v1.0.0/zh_cns.json';

//Map to Find MTS URL
export const localesMap = new Map([
  [localesSupport[0], enJsonUrl],
  [localesSupport[1], scJsonUrl],
]);

//Map for API Calls And Find Files Usage
export const localeIdentifier = new Map([
  ['en', 'en-US'],
  ['zh', 'zh-CN'],
]);

export const globalSite = 'en'; // website which need to show slug
